import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import gsap from "gsap";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import * as Utilities from "./animations/AnimationUtilities";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const iconSize = "40px";
let button;
let bottomOfPage;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: "75px",
    [theme.breakpoints.down("sm")]: {
      top: "70px",
    },
    backgroundColor: theme.palette.colors.paleBlue,
    right: "15px",
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: 899,
  },
  iconContainer: {
    height: iconSize,
    width: iconSize,
    position: "absolute",
    "& svg": {
      height: iconSize,
      width: iconSize,
    },
    "& path": {
      color: theme.palette.colors.orange,
    },
  },
}));

const ScrollToBottom = React.forwardRef((props, ref) => {
  const bottomOfPageRef = ref;

  const buttonRef = React.useRef();
  const svgRef = React.useRef();

  useEffect(() => {
    button = buttonRef.current;
    bottomOfPage = bottomOfPageRef.current;
    const svg = svgRef.current;
    Utilities.centerElement(svg);
    button.addEventListener("click", handleScrollBottomButtonClick, false);

    const buttonHideTL = gsap.timeline();
    buttonHideTL.to(button, { autoAlpha: 0 });
    ScrollTrigger.create({
      animation: buttonHideTL,
      trigger: document.querySelector("body"),
      start: "bottom bottom+=" + window.innerHeight * 0.5,
      end: "+=55px",
      toggleActions: "play none reverse",
    });
    // eslint-disable-next-line
  }, []);

  let scrollToBottomTL;

  function handleScrollBottomButtonClick() {
    button.removeEventListener("click", handleScrollBottomButtonClick, false);
    var height = document.body.scrollHeight;
    var distance = height - window.pageYOffset;
    const speed = 500; // increase to allow faster travel
    const minimumTravelTime = 0.25;
    const maxTravelTime = 20;
    const duration = Math.max(
      minimumTravelTime,
      Math.min(distance / speed, maxTravelTime)
    );
    scrollToBottomTL = gsap.timeline({
      onStart: () => {
        document
          .querySelector("body")
          .addEventListener("click", bodyClickHandler, false);
      },
      onComplete: () => {
        document
          .querySelector("body")
          .removeEventListener("click", bodyClickHandler, false);
        button.addEventListener("click", handleScrollBottomButtonClick, false);
      },
    });
    scrollToBottomTL.to(window, {
      duration: duration,
      scrollTo: bottomOfPage,
    });
  }

  function bodyClickHandler() {
    scrollToBottomTL.paused(!scrollToBottomTL.paused());
    document
      .querySelector("body")
      .removeEventListener("click", bodyClickHandler, false);
    button.addEventListener("click", handleScrollBottomButtonClick, false);
  }

  const classes = useStyles();

  return (
    <div ref={buttonRef} className={classes.root}>
      <span ref={svgRef} className={classes.iconContainer}>
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
    </div>
  );
});

export default ScrollToBottom;
