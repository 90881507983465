import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Link, Box, useMediaQuery } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import DevOpsIcon from "./animations/DevOpsIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    height: theme.sizes.footerHeight,
    width: "100vw",
    backgroundColor: theme.palette.background.footer,
    borderTop: "1px solid " + theme.palette.colors.white,
    position: "fixed",
    bottom: 0,
    zIndex: 1000,
  },
  devOpsIconContainer: {
    textAlign: "center",
    position: "relative",
  },
  footerLinkIcon: {
    marginRight: theme.spacing(0.5),
  },
  footerLinkText: {
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
      paddingRight: "1em",
    },
  },
}));

const footerLinks = [
  { text: "Graphical Site-Map", href: "#" },
  { text: "Technology Research", href: "#" },
  { text: "DevOps Information", href: "#" },
  { text: "YouTube Tutorials", href: "#" },
  { text: "Blog Articles", href: "#" },
];

function Footer(props) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      alignContent="center"
      justifyContent="space-around"
    >
      <Grid item xs={2} className={classes.devOpsIconContainer}>
        <DevOpsIcon />
      </Grid>

      {footerLinks.map((link, i) => {
        const responsiveLinkVisibility =
          i === 0 ? { xs: "block" } : { xs: "none", md: "block" };
        const numberOfColumns = smallScreen ? 6 : 2;
        return (
          <Grid key={"element-key-" + link.text} item xs={numberOfColumns}>
            <Box
              element="span"
              className={classes.footerLinkText}
              align="center"
              display={responsiveLinkVisibility}
            >
              <Link
                href={link.href}
                target="_blank"
                rel="noopener"
                color="secondary"
              >
                <FontAwesomeIcon
                  icon={faListAlt}
                  className={classes.footerLinkIcon}
                />
                {link.text}
              </Link>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Footer;
