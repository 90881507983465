import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    marginBottom: "10px",

    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  image: {
    height: "300px",
    width: "300px",
    borderRadius: "50%",
  },
  heading: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0%)",
    bottom: 0,
  },
  button: {
    "& .MuiButton-iconSizeMedium > *:first-child": {
      fontSize: "15px",
    },
  },
  italicize: {
    fontStyle: "italic",
  },
}));

function SectionTwoBlock(props) {
  const imageSrc = props.imageSrc
    ? props.imageSrc
    : "https://picsum.photos/id/0/300/300";
  const imageAlt = props.imageAlt
    ? props.imageAlt
    : "you should always set an alt";
  const heading = props.heading ? props.heading : "Heading";
  const text = props.text
    ? props.text
    : "Ineluctable modality of the visible: at least that if no more, thought through my eyes. Signatures of all things I am here to read, seaspawn and seawrack, the nearing tide, that rusty boot. Snotgreen, bluesilver, rust: coloured signs. Limits of the diaphane. But he adds: in bodies. Then he was aware of them bodies before of them coloured. How? By knocking his sconce against them, sure. Go easy.";

  const classes = useStyles();

  return (
    <Grid className={classes.root} item xs={12} md={4}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={imageSrc} alt={imageAlt} />
      </div>
      <Typography className={classes.heading} variant="h5">
        {heading}
      </Typography>
      <Typography className={classes.text}>{text}</Typography>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          size="medium"
          endIcon={<FontAwesomeIcon icon={["fas", "angle-double-right"]} />}
        >
          View Details
        </Button>
      </div>
    </Grid>
  );
}

export default SectionTwoBlock;
