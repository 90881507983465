import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import gsap from "gsap";
import * as Utilities from "../../animations/AnimationUtilities";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    visibility: "hidden",
    minHeight: "calc(100vh - 95px)",
    backgroundColor: theme.palette.colors.darkBlue,
  },
  message: {
    position: "absolute",
    width: "100%",
    padding: "0 1em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
}));

function TemplateFullScreenDiv(props) {
  const containerRef = React.useRef();
  const messageRef = React.useRef();

  useEffect(() => {
    const container = containerRef.current;
    const message = messageRef.current;

    // center content before it is shown
    Utilities.centerElement(message);

    // show content after it is ready
    gsap.to(container, { autoAlpha: 1 });
  }, []);

  const classes = useStyles();

  return (
    <div ref={containerRef} className={classes.root}>
      <Typography
        ref={messageRef}
        className={classes.message}
        variant="h3"
        align="center"
      >
        I'm centered in this full screen div by my ref
      </Typography>
    </div>
  );
}

export default TemplateFullScreenDiv;
