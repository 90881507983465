/*
  Utility functions for GSAP
*/

import { gsap } from "gsap";

function centerElement(element, topPosition) {
  topPosition = topPosition ? topPosition + "%" : "50%";
  gsap.set(element, {
    xPercent: -50,
    yPercent: -50,
    left: "50%",
    top: topPosition,
  });
}

function showElement(element, duration = 0.2) {
  gsap.to(element, { duration: duration, autoAlpha: 1 });
}

function hideElement(element, duration = 0.2) {
  gsap.to(element, { duration: duration, autoAlpha: 0 });
}

// shuffle and return a copy of an array with derangement (Sattolo algorithm)
function sattoloShuffleCopy(array) {
  var newArray = array.slice(0);
  for (var i = newArray.length; i-- > 1; ) {
    var j = Math.floor(Math.random() * i);
    var tmp = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = tmp;
  }
  return newArray;
}

function rollForPositiveNegative(number) {
  if (gsap.utils.random(0, 1) > 0.5) {
    number *= -1;
  }
  return number;
}

function createFullscreenDemoTimeline(timelineControlsElement) {
  const mainTL = gsap.timeline({
    onComplete: () => {
      gsap.to(timelineControlsElement, {
        opacity: 1,
        duration: 0.4,
        delay: 0.5,
      });
    },
  });
  return mainTL;
}

function createDevTools(timeline, controlsElement) {
  const devToolsOptions = {
    animation: timeline,
    visibility: "auto",
    minimal: true,
    keyboard: false,
    container: controlsElement,
  };
  return devToolsOptions;
}

export {
  centerElement,
  showElement,
  hideElement,
  sattoloShuffleCopy,
  rollForPositiveNegative,
  createFullscreenDemoTimeline,
  createDevTools,
};
