import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from "@material-ui/core";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin);

const useStyles = makeStyles((theme) => ({
  root: {},
  listHeader: {
    "& *": {
      color: theme.palette.colors.black,
      fontWeight: 600,
    },
  },
  listItemText: {
    color: theme.palette.colors.black,
  },
}));

function OffscreenTableOfContentsTemporary(props) {
  const sortedSkillsList = props.sortedSkillsList;
  const skillSectionRefs = props.skillSectionRefs;
  let skillSections;

  const [drawerState, setDrawerState] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  let scrollTL = gsap.timeline();

  function handleContentsItemClick(i) {
    toggleDrawer();
    const target = skillSections[i];
    const distance = target.getBoundingClientRect().top - window.pageYOffset;
    const speed = 2000; // increase to allow faster travel
    const minimumTravelTime = 0.25;
    const maxTravelTime = 20;
    const duration = Math.max(
      minimumTravelTime,
      Math.min(distance / speed, maxTravelTime)
    );
    scrollTL.clear();
    scrollTL.to(window, {
      duration: duration,
      scrollTo: { y: target, offsetY: 150 },
      ease: "Power1.inOut",
      onStart: () => {
        document
          .querySelector("body")
          .addEventListener("click", bodyClickHandler);
      },
      onComplete: () => {
        document
          .querySelector("body")
          .removeEventListener("click", bodyClickHandler);
      },
    });
  }

  function bodyClickHandler() {
    scrollTL.kill();
    document
      .querySelector("body")
      .removeEventListener("click", bodyClickHandler);
  }

  useEffect(() => {
    // eslint-disable-next-line
    skillSections = skillSectionRefs.map((ref) => ref.current);
  });

  const classes = useStyles();
  return (
    <>
      <Button variant="contained" color="secondary" onClick={toggleDrawer}>
      <Typography style={{ textAlign:'left', minWidth: '358px', textTransform: 'none' }}>Click Here to See Skill List Menu</Typography>
      </Button>
      <Drawer
        className={classes.drawer}
        open={drawerState}
        onClose={toggleDrawer}
        variant="temporary"
        anchor="left"
      >
        <List>
          <ListItem key="list header">
            <ListItemText
              className={classes.listHeader}
              primary="Skills Table of Contents"
            />
          </ListItem>
          <Divider />
          {sortedSkillsList.skillCategories.map((category, i) => {
            return (
              <div key={"list fragment - " + i}>
                <ListItem
                  button
                  key={category + "-" + i}
                  onClick={() => handleContentsItemClick(i)}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={category}
                  />
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </Drawer>
    </>
  );
}

export default OffscreenTableOfContentsTemporary;
