import HomePage from "./pages/home/HomePage";
import SkillsPage from "./pages/skills/SkillsPage";
import PassionProjects from "./pages/svg/SvgPage";
import KubernetesCluster from "./pages/template/TemplatePage";
import Testimonials from "./pages/template/TemplatePage";
import WebApps from "./pages/template/TemplatePage";
import YouTubeVideos from "./pages/slider-template/SliderTemplatePage";
import TechTrends from "./pages/template/TemplatePage";
import ScottsResume from "./pages/resume/ResumePage";
import LoginPage from "./pages/login/LoginPage";
import RegisterPage from "./pages/register/RegisterPage";
//import SvgPage from "./pages/svg/SvgPage";

//NOTE: for future reference: My Netlify automated DevOps complains (which is good thing) about "no-unused-vars".
//  2:27:25 PM: ./src/SiteMap.js
//  2:27:25 PM:   Line 10:8:  'SvgPage' is defined but never used  no-unused-vars

function SiteMap() {
  const pages = [
    // home
    {
      path: "/home",
      Component: HomePage,
      title: "ScottDevOps.com",
      linkText: "Home Page",
      hideThisinNavBar: false,
    },
    // blog
    {
      path: "https://scottblogsitefrontend-staging.herokuapp.com/",
      linkText: "Scott's Blog",
      externalLink: true,
      hideThisinNavBar: false,
    },
    // skills
    {
      path: "/skills",
      Component: SkillsPage,
      title: "Skills Page",
      linkText: "My Skills List",
      hideThisinNavBar: false,
    },
    // youtube videos
    {
      path: "/youtube-videos",
      Component: YouTubeVideos,
      title: "YouTube Videos",
      linkText: "My YouTube Videos",
      hideThisinNavBar: false,
    },
    // tech trends
    {
      path: "/tech-trends",
      Component: TechTrends,
      title: "Tech Trends",
      linkText: "Awesome Tech Topics",
      hideThisinNavBar: false,
    },
    // register page
    {
      path: "/register",
      Component: RegisterPage,
      title: "Register Page",
      linkText: "Register an Account",
      hideThisinNavBar: false,
    },
    // current resume
    {
      path: "/current-resume",
      Component: ScottsResume,
      title: "My Resume",
      linkText: "My Resume *",
      hideThisinNavBar: false,
    },
    // svg page
    //{
    //  path: "/svg",
    //  Component: SvgPage,
    //  title: "Svg Page",
    //  linkText: "Cool Svg Files",
    //},
    // passion projects
    {
      path: "/passion-projects",
      Component: PassionProjects,
      title: "Passion Projects",
      linkText: "Passion Projects *",
      hideThisinNavBar: false,
    },
    // testimonials
    {
      path: "/testimonials",
      Component: Testimonials,
      title: "Testimonials",
      linkText: "Testimonials *",
      hideThisinNavBar: false,
    },
    // kubernetes cluster
    {
      path: "/kubernetes-cluster",
      Component: KubernetesCluster,
      title: "Kubernetes Cluster",
      linkText: "I Built a Kubernetes Cluster *",
      hideThisinNavBar: false,
    },
    // web apps
    {
      path: "/web-apps",
      Component: WebApps,
      title: "Web Apps",
      linkText: "Web Apps I Built/Maintained *",
      hideThisinNavBar: false,
    },
    // login page
    {
      path: "/login",
      Component: LoginPage,
      title: "Login Page",
      linkText: "Login to System",
      hideThisinNavBar: true,
    },
    // default
    {
      path: "*",
      Component: HomePage,
      title: "ScottDevOps.com",
      hideThisinNavBar: false,
    },
  ];

  return pages;
}

export default SiteMap;
