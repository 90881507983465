import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import backgroundImage from "../../img/acebackground-rotated.jpg";
import { ReactComponent as SVG } from "../../img/biker-optimized.svg";
import "../../css/biker.css";
import gsap from "gsap";
import * as Utilities from "../../animations/AnimationUtilities";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflowX: "hidden",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    height: "50vh",
    position: "relative",
  },
  contentContainer: {
    margin: "50px 0",
  },
  svg: {
    position: "absolute",
    height: "85%",
    width: "auto",
  },
}));

function HomePageEnd(props) {
  const contentWrapperRef = React.useRef();
  const svgRef = React.useRef();

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    const svg = svgRef.current;
    Utilities.centerElement(svg);

    // reveal container
    const mainTL = gsap.timeline();
    mainTL.set(contentWrapper, { autoAlpha: 1 });
  }, []);

  const classes = useStyles();

  return (
    <div ref={contentWrapperRef} className={classes.contentWrapper}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center" alignItems="center">
          <Grid className={classes.imgContainer} item xs={11} md={5}>
            <SVG ref={svgRef} className={classes.svg} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default HomePageEnd;
