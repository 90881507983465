import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Link } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faTasks,
  faNetworkWired,
  faEdit,
  faFile,
  faComment,
  faHeart,
  faChartLine,
  faCode,
  faListAlt,
  faUserCog,
  faAtom,
} from "@fortawesome/free-solid-svg-icons";
import ContentBlock from "../../ContentBlock";

library.add(fab);

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: "50px auto",
  },
}));

function HomePageBlocksSectionOne(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.contentContainer}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="My Skills List"
            icon={<FontAwesomeIcon icon={faTasks} />}
            content={
              <span>
                View many different types of hardware, software, networking,
                applications, tools and frameworks I have worked with.{" "}
                <Link href="#" color="secondary">
                  Click here
                </Link>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Kubernetes Cluster"
            icon={<FontAwesomeIcon icon={faNetworkWired} />}
            content="I designed and built a four-node kubernetes cluster using Ubuntu Linux server operating system and Raspberry Pi 4"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="YouTube Videos"
            icon={<FontAwesomeIcon icon={["fab", "youtube"]} />}
            content="View a list of my YouTube videos that talk about topics such as Azure, DevOps, VSCODE and more"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Blogging Site"
            icon={<FontAwesomeIcon icon={faEdit} />}
            content="View Scott's Blogging Site that was built using C#, .Net Core, and the Piranha CMS framework"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Current Resume"
            icon={<FontAwesomeIcon icon={faFile} />}
            content="Here you can view an online version and download a copy of Scott's most up-to-date resume"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Testimonials"
            icon={<FontAwesomeIcon icon={faComment} />}
            content="Here you can view reference letters, and testimonials written about Scott both as an individual and as a business owner"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Passion Projects"
            icon={<FontAwesomeIcon icon={faHeart} />}
            content="View some of the cool and interesting projects that Scott created with both software and hardware in some cases"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Websites &amp; apps"
            icon={<FontAwesomeIcon icon={faCode} />}
            content="Here is a list of screenshots of some of the various web applications, and websites that Scott has worked on over the years"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Trends in Tech"
            icon={<FontAwesomeIcon icon={faChartLine} />}
            content="Here is a list of some newer technology and trends in the world of software and technology"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="ToDo  Project Tracker"
            icon={<FontAwesomeIcon icon={faListAlt} />} 
            content="Here are a few applications you can try out for free, and purchase a subscription if you find them useful enough"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Membership Database"
            icon={<FontAwesomeIcon icon={faUserCog} />} 
            content="Need web-based software to help manage your organization and its list of members and activities? Review this web page to find out more"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="FUEL KB"
            icon={<FontAwesomeIcon icon={faAtom} />} 
            content="This is an application that can help organizations get a handle on managing and reducing technical debts thereby saving costs"
          />
        </Grid>

      </Grid>
    </Container>
  );
}

export default HomePageBlocksSectionOne;
