import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAltV,
  faArrowsAlt,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import ContentBlock from "../../ContentBlock";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: "50px auto",
  },
}));

function HomePageBlocksSectionTwo(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.contentContainer}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Alignment &amp; Space"
            icon={<FontAwesomeIcon icon={faArrowsAltV} />}
            content="A more efficient way to lay out, align and distribute space among items in a container"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Tricky Positioning"
            icon={<FontAwesomeIcon icon={faArrowsAlt} />}
            content="Flexbox usually solves tricky problems including how to position or dynamically resize elements on a page"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ContentBlock
            header="Responsive Design"
            icon={<FontAwesomeIcon icon={faMobile} />}
            content="Flexbox makes building a website layout (and making it responsive!) much, much easier"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomePageBlocksSectionTwo;
