import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid } from "@material-ui/core";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiLink from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    visibility: "hidden",
    "& *:hover": {
      textDecoration: "none",
    },
  },
  background: {
    backgroundColor: theme.palette.colors.darkBlue,
    boxShadow: theme.palette.boxShadow,
    transition: "box-shadow 0.3s",
    height: "100%",
    width: "100%",
    position: "relative",
    "&:hover": {
      boxShadow: "none",
    },
  },
  hasLink: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  noLink: {
    padding: theme.spacing(2),
  },
  skillBoxHeader: {
    fontSize: "1.5em",
    fontWeight: 700,
    textAlign: "left",
  },
  skillBoxHeaderIcon: {
    marginRight: theme.spacing(2),
  },
  linkFlag: {
    position: "absolute",
    top: "5px",
    right: "5px",
    height: "15px",
    width: "15px",
    "& path": {
      fill: "#eee",
    },
  },
  imageContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  skillImage: {
    height: "125px",
    width: "auto",
  },
}));

function SkillBox(props) {
  const header = props.header ? props.header : false;
  const hasHeader = header === false ? false : true;
  const icon = props.icon ? props.icon : false;
  const hasIcon = icon === false ? false : true;
  const mediumColumns = props.mediumColumns ? props.mediumColumns : 12;
  const isLink = props.link ? true : false;
  const externalLink = props.externalLink ? props.externalLink : false;
  const internalLink = props.internalLink ? props.internalLink : false;
  const imageOne = props.imageOne ? props.imageOne : false;
  const imageTwo = props.imageTwo ? props.imageTwo : false;

  const boxRef = React.useRef();

  useEffect(() => {
    const box = boxRef.current;

    const mainTL = gsap.timeline();
    mainTL.set(box, { y: 30 });
    mainTL.to(box, { autoAlpha: 1, y: 0 });

    ScrollTrigger.create({
      animation: mainTL,
      trigger: box,
      start: "top 80%",
      end: "top 60%",
      scrub: 0.5,
    });
  }, []);

  const classes = useStyles();
  const backgroundPaddingClass = isLink ? classes.hasLink : classes.noLink;
  const backgroundClass = `${classes.background} ${backgroundPaddingClass}`;

  const basicMarkup = (
    <Paper className={backgroundClass} elevation={0}>
      {hasHeader && (
        <Typography className={classes.skillBoxHeader} align="center">
          {hasIcon && (
            <span className={classes.skillBoxHeaderIcon}>
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
          {header}
        </Typography>
      )}
      {imageOne && (
        <div className={classes.imageContainer}>
          <img
            className={classes.skillImage}
            src={imageOne}
            alt="content related to the skill"
          />
        </div>
      )}
      {imageTwo && (
        <div className={classes.imageContainer}>
          <img
            className={classes.skillImage}
            src={imageTwo}
            alt="a second piece of content related to the skill"
          />
        </div>
      )}
      {isLink && (
        <FontAwesomeIcon
          className={classes.linkFlag}
          icon={["fas", "external-link"]}
        />
      )}
    </Paper>
  );

  const internalLinkMarkup = (
    <RouterLink to={internalLink}>{basicMarkup}</RouterLink>
  );

  const externalLinkMarkup = (
    <MuiLink href={externalLink} target="_blank" rel="noopener">
      {basicMarkup}
    </MuiLink>
  );

  let markup;
  if (internalLink) {
    markup = internalLinkMarkup;
  } else if (externalLink) {
    markup = externalLinkMarkup;
  } else {
    markup = basicMarkup;
  }

  return (
    <Grid item xs={12} md={mediumColumns} ref={boxRef} className={classes.root}>
      {markup}
    </Grid>
  );
}

export default SkillBox;
