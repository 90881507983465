import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import HomePageHeaderText from "./HomePageHeaderText";
import AcronymGrid from "../../animations/AcronymGrid";
import gsap from "gsap";
import { SplitText } from "gsap/all";

gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    padding: "2em 0",
    minHeight: "calc(100vh - 100px)",
  },
  headerContainer: {
    maxWidth: "1100px",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "80%",
    },
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    textAlign: "left",
    fontSize: "34px",
    marginLeft: "20px",
    visibility: "hidden",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: "0",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "56px",
    },
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: "3.3rem",
  },
  acronymGridContainer: {
    position: "relative",
    [theme.breakpoints.only("sm")]: {
      margin: "30px 0",
    },
    [theme.breakpoints.only("xs")]: {
      margin: "0",
    },
  },
}));

function HomePageHeader(props) {
  const siteHeadlineRef = React.useRef();

  useEffect(() => {
    const siteHeadline = siteHeadlineRef.current;
    const text = new SplitText(siteHeadline, { type: "words, chars" });
    const headlineTL = gsap.timeline();
    headlineTL.set(text.chars, { autoAlpha: 0 });
    headlineTL.set(siteHeadline, { autoAlpha: 1 });
    headlineTL.to(
      text.chars,
      { autoAlpha: 1, duration: 0.3, stagger: 0.05 },
      0.1
    );
  }, []);

  const classes = useStyles();

  return (
    <Grid
      className={classes.gridContainer}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid container className={classes.headerContainer}>
        <Grid className={classes.hero} item xs={12} md={7}>
          <Typography
            ref={siteHeadlineRef}
            variant="h1"
            className={classes.headerH1}
          >
            Scott's Portfolio Landing Page
          </Typography>
          <HomePageHeaderText />
        </Grid>
        <Grid item xs={12} md={5} className={classes.acronymGridContainer}>
          <AcronymGrid />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HomePageHeader;
