import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Link, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { ToastContainer, toast } from "react-toastify";
//minified version is also included
//import 'react-toastify/dist/ReactToastify.min.css';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    minHeight: "calc(100vh - 95px)",
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    fontSize: theme.typography.headerH1.fontSize,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    padding: "2em 0",
  },
  formFormat: { fontSize: "0.9rem", padding: "0.5em" },
  formFields: { margin: "0.2em", width: "175px" },
  formErrors: { color: "red", fontSize: "0.7rem" },
  formSubmitButton: {
    marginTop: "25px",
    marginLeft: "5px",
    marginBottom: "35px",
  },
  headerImage: {
    textAlign: "center",
  },
}));
///////////////////////////////////// NOTES ///////////////////////////////////////
// 1. This is the Register form to allow someone to acquire a new User account in the strapi backend for Scotts Portfolio Site
// 2. We will get back a jwt token if all goes well and once we have that jwt,
//    we want to determine what routes are protected in the frontend,
//    so, based on the jwt existence (i.e. user was authenticated) then the user
//    will be allowed or blocked from using the routes in the front end
// 3. useForm({})  https://react-hook-form.com/api/useform/
function RegisterPageForm(props) {
  toast.configure();

  // Custom hook to mange the entire register form. useForm is custom hook for managing forms with ease. It takes optional arguments
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "username",
      email: "iest@gmail.com",
      password: "12345678",
      confirmPassword: "12345678",
    },
  });

  const navigate = useNavigate();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  console.info(
    `INFO: In RegisterPageForm, here is shouldRedirect value: ${shouldRedirect}`
  );
  console.info(`INFO: value of is NODE_ENV: ${process.env.NODE_ENV}`);

  // createUser will call our Strapi backend (local or in production server) to create account and get a jwt token
  // TODO: discuss use of process.env in development versus production environments
  const createUser = async (username, email, password) => {
    const strapiURL =
      process.env.STRAPIBACKEND_API_URL || "http://localhost:1337";
    const strapiREGISTERMETHOD =
      process.env.STRAPIBACKEND_API_REGISTER_METHOD || "/auth/local/register";
    const STRAPI_REGISTER_URL = strapiURL + strapiREGISTERMETHOD;
    console.info(`INFO: STRAPI_REGISTER_URL IS  ${STRAPI_REGISTER_URL}`);

    try {
      //console.info(`INFO: Inside Try stmnt calling strapi Register method.`);
      const response = await fetch(
        "https://scottblogsitebackend-staging.herokuapp.com/api/auth/local/register",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ username, email, password }),
        }
      );

      //     return fetch(url, request)
      //       .then(response => response.status >= 400 && response.status < 600
      //       ? response.json().then(error => {throw new Error(error.message)})
      //       : response);
      const jsonResponse = await response.json();

      // Lets see what strapi gave back to us. Could be that their password was too short, and we got a 400 status code
      console.info(
        `INFO: What happened with the fetch() call, the HTTP status was: ${response.status}`
      );

      if (response.status === 400) {
        // toast("🦄 Server Error!", {    // This toast works fine if you want to use it elsewhere, can customize lots of settings
        //   position: "top-right",
        //   autoClose: 3500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });

        if (jsonResponse.error.message !== null) {
          // console.log("ERROR: jsonResponse: ");
          // console.log(jsonResponse);
          console.log(`ERROR: error.message: ${jsonResponse.error.message}`);
          if (jsonResponse.error.message !== "") {
            toast.warning(
              `Error: Server issue occured: ${jsonResponse.error.message}`
            );
          }
        }

        //https://fkhadra.github.io/react-toastify/introduction/
        //console.info(
        //  `ERROR: We got a 400 HTTP response, here is the error: ${response.statusText}`
        //);
        //console.info(response);
        return;

        //reset({ ...data }); // reset the form
      }

      if (response.status === 200) {
        console.info(
          "SUCCESS: calling CreateUser() was sucessful! We got back a 200 response code. YaY!"
        );
        toast.success("SUCCESS your account was initialized");

        setShouldRedirect(true);
        navigate("/");
      } else {
        // bad things (toast things)
        toast.warning("Error: Server issue occured");
      }
    } catch (error) {
      //console.info("ERROR: catch statement of createUser");
      toast.warning("Catch Error: Server issue occured");
      //console.info(error);
      throw new Error(error); // TODO: What to do with this?
    }
  };

  useEffect(() => {
    toast.success("Welcome!");
  }, []);

  const classes = useStyles();

  const onSubmit = async (data) => {
    // Also note that if we make these all required fields, this condition should never happen
    if (!data.username || !data.email || !data.password) {
      //console.info(
      //  "WARNING: Username, Email or password information is missing"
      //);
      //alert("WARNING: Username, Email or password information is missing.");
      toast.warning("Username, Email or password information is missing.");

      return;
    }

    if (data.password !== data.confirmPassword) {
      console.info(
        "WARNING: Passwords did not match. So we did not call the REGISTER function, so lets return back, because data is no good"
      );
      //alert("WARNING: Passwords did not match");
      toast.warning("Passwords did not match");
      console.info(data);
      return;
    }
    // TODO1: Should we capture a return value from createUser, so then I could do something with that return value?
    // or will all conditions be handled in the createUser method? Like what if we get a failure of any kind,
    // should we just put out a toast message here, and not handle invidiaul errors in the createUser function.
    await createUser(data.username, data.email, data.password);
    return; //TODO2: Do we need these return statements in the code above?
  };

  return (
    <Grid
      className={classes.headerContainer}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid container item xs={11}>
        <Grid className={classes.hero} item xs={12} md={7}>
          <Typography variant="h1" className={classes.headerH1} align="center">
            Register an Account
          </Typography>
          <Typography className={classes.subHeader}>
            This is the place to <u>register</u> for an account with our system.
            Once you submit your information (you will receive an email
            confirmation that you need to click on). For extra security
            measures, your new account will be reviewed by an admin of this
            site, before granting access to you. You can return to the{" "}
            <Link
              href="/login"
              target="_blank"
              rel="noopener"
              color="secondary"
            >
              Login Page.
            </Link>
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formFormat}
          >
            <label>
              <input
                className={classes.formFields}
                {...register("username", { maxLength: 20 })}
              />{" "}
              &lt;
              {/* errors will return when field validation fails  */} User Name
            </label>
            {errors.username && errors.username.type === "maxLength" && (
              <span className={classes.formErrors}> 20 char max</span>
            )}
            {errors.username && (
              <span className={classes.formErrors}>
                <i> required </i>
              </span>
            )}
            <br />
            <label>
              <input
                className={classes.formFields}
                {...register("email", { required: true, maxLength: 35 })}
              />{" "}
              &lt; Email
            </label>
            {errors.email && (
              <span className={classes.formErrors}>
                <i> required </i>
              </span>
            )}
            <br />
            <label>
              <input
                className={classes.formFields}
                {...register("password", { required: true, maxLength: 16 })}
              />{" "}
              &lt; Password
            </label>
            {errors.password && (
              <span className={classes.formErrors}>
                <i> required </i>
              </span>
            )}
            <br />
            <label>
              <input
                className={classes.formFields}
                {...register("confirmPassword", {
                  required: true,
                  maxLength: 16,
                })}
              />{" "}
              &lt; Confirm it
            </label>
            {errors.confirmPassword && (
              <span className={classes.formErrors}>
                <i> required </i>
              </span>
            )}
            <div>
              <Button
                className={classes.formSubmitButton}
                variant="contained"
                color="secondary"
                type="submit"
              >
                <Typography
                  style={{
                    textAlign: "right",
                    minWidth: "170px",
                    textTransform: "none",
                  }}
                >
                  Register Now {` `}
                  <FontAwesomeIcon icon="fa-solid fa-address-card" />
                </Typography>
              </Button>
            </div>
          </form>
        </Grid>
        <Grid className={classes.headerRight} item xs={12} md={5}>
          <div className={classes.headerImage}>
            <img
              src="https://i.picsum.photos/id/2/400/300.jpg?grayscale&hmac=oV_ybjwxp4S7HHkw6y0u-oWRpcIlG9NWsJzZ1eGMCL4"
              alt="login"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RegisterPageForm;
