import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { ReactComponent as SVG } from "../../img/tiger.svg";


const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    minHeight: "calc(100vh - 95px)",
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    fontSize: theme.typography.headerH1.fontSize,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    padding: "2em 0",
  },
  headerImage: {
    textAlign: "center",
  },
}));

function SvgHeader(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  return (
    <Grid
      className={classes.headerContainer}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid container item xs={8}>
        <Grid className={classes.hero} item xs={12} md={12}>
          <Typography variant="h1" className={classes.headerH1} align="center">
            Passion Project - List of SVG Files Page
          </Typography>
          <Typography className={classes.subHeader}>
            This page contains some SVG files that I find interesting. 
            The first svg file is the classic Tiger SVG file. You can scale it up or down
            and it always looks fantastic.
          </Typography>
          <div>
          <SVG />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SvgHeader;
