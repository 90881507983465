import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Hidden } from "@material-ui/core";
import SkillsPageHeaderText from "./SkillsPageHeaderText";
import AcronymGrid from "../../animations/AcronymGrid";
// import CollapsibleTableOfContents from "./CollapsibleTableOfContents";
import OffscreenTableOfContentsTemporary from "./OffscreenTableOfContentsTemporary";
import gsap from "gsap";
import { SplitText } from "gsap/all";

gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: theme.palette.background.header,
    // overflow: "hidden",
    position: "relative",
    padding: "2em 0",
    minHeight: "calc(100vh - 100px)",
  },
  headerContainer: {
    maxWidth: "1100px",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "80%",
    },
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    textAlign: "left",
    fontSize: "34px",
    marginLeft: "20px",
    visibility: "hidden",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: "0",
      marginTop: "1.5em",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "56px",
    },
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: "3.3rem",
  },
  acronymGridContainer: {
    position: "relative",
    [theme.breakpoints.only("sm")]: {
      margin: "30px 0",
    },
    [theme.breakpoints.only("xs")]: {
      margin: "0",
    },
  },
  tableOfContentsContainerMdUp: {
    marginLeft: "20px",
  },
  drawerButtonSmall: {
    textAlign: "center",
  },
}));

function SkillsPageHeader(props) {
  const sortedSkillsList = props.sortedSkillsList;
  const skillSectionRefs = props.skillSectionRefs;
  const pageHeadlineRef = React.useRef();

  useEffect(() => {
    const pageHeadline = pageHeadlineRef.current;
    const text = new SplitText(pageHeadline, { type: "words, chars" });
    const headlineTL = gsap.timeline();
    headlineTL.set(text.chars, { autoAlpha: 0 });
    headlineTL.set(pageHeadline, { autoAlpha: 1 });
    headlineTL.to(
      text.chars,
      { autoAlpha: 1, duration: 0.3, stagger: 0.05 },
      0.1
    );
  }, []);

  const classes = useStyles();

  return (
    <Grid className={classes.gridContainer} container justifyContent="center">
      <Grid
        container
        className={classes.headerContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Grid className={classes.hero} item xs={12} md={7}>
          <Typography
            ref={pageHeadlineRef}
            variant="h1"
            className={classes.headerH1}
          >
            Scott's Skills List
          </Typography>
          <SkillsPageHeaderText />
          <Hidden smDown>
            <Grid container className={classes.tableOfContentsContainerMdUp}>
              <Grid item md={3}>
                <OffscreenTableOfContentsTemporary
                  skillSectionRefs={skillSectionRefs}
                  sortedSkillsList={sortedSkillsList}
                />
              </Grid>
            </Grid>
            {/* Hide this drop down
            <Grid container className={classes.tableOfContentsContainerMdUp}>
              <Grid item md={8} lg={8} xl={7}>
                <CollapsibleTableOfContents
                  skillSectionRefs={skillSectionRefs}
                  sortedSkillsList={sortedSkillsList}
                />
              </Grid>
            </Grid> 
            */}

          </Hidden>
        </Grid>
        <Grid item xs={12} md={5} className={classes.acronymGridContainer}>
          <AcronymGrid />
        </Grid>
      </Grid>
      <Hidden mdUp>
        <Grid container justifyContent="center">
          <Grid item xs={10} className={classes.drawerButtonSmall}>
            <OffscreenTableOfContentsTemporary
              skillSectionRefs={skillSectionRefs}
              sortedSkillsList={sortedSkillsList}
            />
          </Grid>
        </Grid>
        {/*
        <Grid container justifyContent="center">
          <Grid
            className={classes.tableOfContentsContainerSm}
            item
            xs={10}
            sm={8}
          >
            <CollapsibleTableOfContents
              skillSectionRefs={skillSectionRefs}
              sortedSkillsList={sortedSkillsList}
            />
          </Grid>
        </Grid>
        */}
      </Hidden>
    </Grid>
  );
}

export default SkillsPageHeader;
