import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SVG } from "../img/dev-ops-optimized.svg";
import gsap from "gsap";
import * as Utilities from "./AnimationUtilities";

const COLORS = {
  pathInactive: "#0151cc",
  pathActive: "#fafafa",
  title: "#fb8c00",
  titleActive: "#ffcc80",
  textInactive: "#0151cc",
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: "#f7f7f7",
    position: "fixed",
    zIndex: "100",
    overflow: "hidden",
  },
  animationContainer: {
    position: "relative",
    height: "100%",
    visibility: "hidden",
  },
  svg: {
    width: "100%",
    maxWidth: "800px",
    height: "auto",
    visibility: "hidden",
    overflow: "hidden",
    position: "absolute",
    zIndex: 100,
    "& #title-Dev, #title-Ops": {
      fill: COLORS.title,
    },
    "& #text path": {
      fill: COLORS.pathInactive,
      stroke: COLORS.pathInactive,
      strokeWidth: "1",
    },
    "& #path-pieces path": {
      fill: COLORS.pathInactive,
    },
    "& #background": {
      visibility: "hidden",
    },
  },
  message: {
    position: "absolute",
    zIndex: 1,
    fontSize: "3em",
  },
}));

function DevOps(props) {
  const animationContainerRef = React.useRef();
  const svgRef = React.useRef();

  useEffect(() => {
    // ELEMENTS
    const animationContainer = animationContainerRef.current;
    const svg = svgRef.current;

    // SETUP
    Utilities.centerElement(svg);

    // animation elements
    const title = [
      svg.querySelector("#title-Dev"),
      svg.querySelector("#title-Ops"),
    ];
    const pathPieces = [
      svg.querySelector("#path-pieces #path-piece-plan"),
      svg.querySelector("#path-pieces #path-piece-code"),
      svg.querySelector("#path-pieces #path-piece-build"),
      svg.querySelector("#path-pieces #path-piece-test"),
      svg.querySelector("#path-pieces #path-piece-release"),
      svg.querySelector("#path-pieces #path-piece-deploy"),
      svg.querySelector("#path-pieces #path-piece-operate"),
      svg.querySelector("#path-pieces #path-piece-monitor"),
    ];

    // ANIMATE
    const mainTL = gsap.timeline();
    mainTL.to(animationContainer, { autoAlpha: 1, duration: 0.2 }, 0.1);
    mainTL.set(svg, { autoAlpha: 1 });

    mainTL.add("start", 0.3);
    mainTL.add(animatePathPieces(pathPieces), "start");
    mainTL.add(animateTitle(title), "start");
  }, []);

  const classes = useStyles();

  return (
    <div ref={animationContainerRef} className={classes.animationContainer}>
      <SVG ref={svgRef} className={classes.svg} />
    </div>
  );
}

// animation functions
function animatePathPieces(pieces) {
  const tl = gsap.timeline();
  const numberOfPieces = pieces.length;
  const duration = 0.3;
  const pause = 0.4;
  const stagger = duration + pause;
  const repeatDelay = stagger * (numberOfPieces - 1) + pause;

  tl.to(pieces, {
    fill: COLORS.pathActive,
    duration: duration,
    stagger: { repeat: -1, each: stagger, repeatDelay },
  });
  tl.to(
    pieces,
    {
      fill: COLORS.pathInactive,
      duration: duration,
      stagger: {
        each: stagger,
        repeat: -1,
        repeatDelay: repeatDelay,
      },
    },
    stagger
  );

  return tl;
}

function animateTitle(title) {
  const tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });

  const dev = title[0].querySelectorAll("path");
  const ops = title[1].querySelectorAll("path");

  tl.add("start");
  tl.to([dev, ops], {
    fill: COLORS.titleActive,
    duration: 0.4,
    stagger: {
      each: 0.2,
      repeat: 1,
      yoyo: true,
    },
  });

  return tl;
}

export default DevOps;
