import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import gsap from "gsap";
import { ScrollTrigger, SplitText } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    color: theme.palette.colors.black,
    backgroundColor: theme.palette.colors.transparentBlue,
    padding: "50px 0",
  },
  headerContainer: {
    marginBottom: "50px",
  },
  sectionHeader: {
    fontSize: "34px",
    fontWeight: 700,
    textAlign: "center",
  },
  sectionText: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: "18px",
    fontWeight: 400,
  },
  textContainer: {
    textAlign: "left",
  },
  chartCell: {},
  chartCellText: {
    height: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.colors.transparentViolet,
    border: "1px solid rgba(86, 61, 124, 0.2)",
    borderRadius: "10px",
    fontSize: "16px",
  },
  chartTerm: {
    fontWeight: 600,
  },
}));

function AzureDevopsProcessFlowchart(props) {
  const contentWrapperRef = React.useRef();
  const headlineRef = React.useRef();
  const textBlockOneRef = React.useRef();

  const chartCells = [
    {
      term: "CI (CI/CD)",
      definition: "Continuous Integration",
      columnsXS: 12,
      columnsSM: 4,
      columnsMD: 4,
    },
    {
      term: "CD (CI/CD)",
      definition: "Continuous Deployment",
      columnsXS: 12,
      columnsSM: 4,
      columnsMD: 4,
    },
    {
      term: "Agents",
      definition: "Perform DevOps Processing on local in-house server",
      columnsXS: 12,
      columnsSM: 4,
      columnsMD: 4,
    },
    {
      term: "DevSecOps",
      definition: "Development Security and Operations",
      columnsXS: 12,
      columnsSM: 4,
      columnsMD: 4,
    },
    {
      term: "Azure Portal",
      definition: "the main login site",
      columnsXS: 12,
      columnsSM: 4,
      columnsMD: 4,
    },
    {
      term: "Architecture",
      definition: "of Cloud-native apps",
      columnsXS: 12,
      columnsSM: 4,
      columnsMD: 4,
    },
    {
      term: "Azure App Service",
      definition: "publish your web apps quickly and easily to Azure",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 4,
    },
    {
      term: "Pipelines",
      definition:
        "a place in Azure that allows you to create automation tasks and repeat them easily",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 4,
    },
    {
      term: "Workflows",
      definition: "github allows you to create these automation steps",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 4,
    },
    {
      term: "GitHub Actions",
      definition: "part of the CI/CD processing that Github provides",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 4,
    },
    {
      term: "Repositories",
      definition:
        "secure location on the internet where you can colloborate on source code and applications development",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 4,
    },
    {
      term: "Artifacts",
      definition:
        "devops agents create these components and make them available to be deployed to servers or environments",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 4,
    },
    {
      term: "App Service Plan",
      definition:
        "a resource in Azure to host your web applications and web api's",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 12,
    },
    {
      term: "Git commands",
      definition:
        "used by developers to push their code changes to a central repositories",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 12,
    },
    {
      term: "Microsoft IIS web server",
      definition:
        "is where the compiled application is hosted inside of on-premise server",
      columnsXS: 12,
      columnsSM: 12,
      columnsMD: 12,
    },
  ];
  let chartCellRefsArray = [];
  let chartCellTextRefsArray = [];

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    const headline = headlineRef.current;
    const textBlockOne = textBlockOneRef.current;
    const cells = chartCellRefsArray.map((cell) => cell.current);
    const cellText = chartCellTextRefsArray.map((text) => text.current);

    // hide elements and reveal container
    const mainTL = gsap.timeline();
    mainTL.set(contentWrapper, { autoAlpha: 1 });

    // animate
    // headline
    const headlineStartTween = { x: -100, autoAlpha: 0 };
    const headlineEndTween = { x: 0, autoAlpha: 1 };
    makeScrollEffect(headline, headlineStartTween, headlineEndTween);
    // text block
    const textBlockStartTween = { y: 30, autoAlpha: 0 };
    const textBlockEndTween = { y: 0, autoAlpha: 1 };
    makeScrollEffect(
      textBlockOne,
      textBlockStartTween,
      textBlockEndTween,
      "top 55%"
    );
    // cells
    const cellStartTween = { y: 30, autoAlpha: 0 };
    const cellEndTween = { y: 0, autoAlpha: 1 };
    cells.forEach((cell) => {
      makeScrollEffect(cell, cellStartTween, cellEndTween, "top 60%");
    });
    // cell text
    cellText.forEach((text) => {
      animateCellText(text);
    });
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  return (
    <div ref={contentWrapperRef} className={classes.contentWrapper}>
      <Container maxWidth="lg">
        <Grid
          className={classes.headerContainer}
          container
          justifyContent="center"
        >
          <Grid className={classes.textContainer} item xs={11}>
            <Typography ref={headlineRef} className={classes.sectionHeader}>
              Azure Devops Process Flowchart
            </Typography>
            <Typography ref={textBlockOneRef} className={classes.sectionText}>
              There are five grid tiers to the Bootstrap grid system, one for
              each range of devices supported. I am using them to display some
              of my DevOps Information. Each tier starts at a minimum viewport
              size and automatically applies to the larger devices unless
              overridden.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.chartContainer}
          container
          spacing={2}
          justifyContent="center"
        >
          {chartCells.map((cell, i) => {
            const cellRef = React.createRef();
            const textRef = React.createRef();
            chartCellRefsArray.push(cellRef);
            chartCellTextRefsArray.push(textRef);
            const cellKey = "chart-cell-" + i;
            return (
              <Grid
                ref={cellRef}
                className={classes.chartCell}
                item
                xs={cell.columnsXS}
                sm={cell.columnsSM}
                md={cell.columnsMD}
                key={cellKey}
              >
                <Typography ref={textRef} className={classes.chartCellText}>
                  <span className={classes.chartTerm}>{cell.term}</span> -{" "}
                  {cell.definition}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}

function animateCellText(cellText) {
  const tl = gsap.timeline();
  const text = new SplitText(cellText, { type: "words" });

  tl.set(text.words, { autoAlpha: 0 });
  tl.to(text.words, { autoAlpha: 1, stagger: { amount: 1 } });

  ScrollTrigger.create({
    animation: tl,
    trigger: cellText,
    start: "top 70%",
    end: "top 55%",
    scrub: 0.66,
  });
}

function makeScrollEffect(element, startTween, endTween, endPosition) {
  const endPos = endPosition ? endPosition : "top 60%";
  const tl = gsap.timeline();
  tl.set(element, startTween);
  tl.to(element, endTween);
  ScrollTrigger.create({
    animation: tl,
    trigger: element,
    start: "top 80%",
    end: endPos,
    scrub: 0.66,
  });
}

export default AzureDevopsProcessFlowchart;
