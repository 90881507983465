import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { ReactComponent as SVG } from "../../img/grid.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as Utilities from "../../animations/AnimationUtilities";

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    backgroundColor: theme.palette.colors.darkBlue,
    overflowX: "hidden",
  },
  contentContainer: {
    margin: "50px 0",
  },
  sectionHeader: {
    fontSize: "34px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  sectionText: {
    marginTop: theme.spacing(2),
  },
  underline: {
    textDecoration: "underline",
  },
  imgContainer: {
    textAlign: "center",
    position: "relative",
    height: "500px",
    width: "500px",
    [theme.breakpoints.only("sm")]: {
      maxHeight: "400px",
      maxWidth: "400px",
    },
    [theme.breakpoints.only("xs")]: {
      maxHeight: "250px",
      maxWidth: "250px",
    },
  },
  svg: {
    position: "absolute",
    width: "100%",
    height: "auto",
  },
  textContainer: {
    textAlign: "left",
  },
}));

function MyExperiencesWithDevOps(props) {
  const contentWrapperRef = React.useRef();
  const svgRef = React.useRef();
  const headlineRef = React.useRef();
  const textBlockOneRef = React.useRef();
  const textBlockTwoRef = React.useRef();

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    const svg = svgRef.current;
    const headline = headlineRef.current;
    const textBlockOne = textBlockOneRef.current;
    const textBlockTwo = textBlockTwoRef.current;
    Utilities.centerElement(svg);

    // hide elements and reveal container
    const mainTL = gsap.timeline();
    mainTL.set(contentWrapper, { autoAlpha: 1 });

    // animate
    // svg
    const svgStartTween = { x: -100, autoAlpha: 0 };
    const svgEndTween = { x: 0, autoAlpha: 1 };
    makeScrollEffect(svg, svgStartTween, svgEndTween, "top 45%");
    // headline
    const headlineStartTween = { x: 100, autoAlpha: 0 };
    const headlineEndTween = { x: 0, autoAlpha: 1 };
    makeScrollEffect(headline, headlineStartTween, headlineEndTween);
    // text blocks
    const textBlockStartTween = { y: 30, autoAlpha: 0 };
    const textBlockEndTween = { y: 0, autoAlpha: 1 };
    makeScrollEffect(
      textBlockOne,
      textBlockStartTween,
      textBlockEndTween,
      "top 55%"
    );
    makeScrollEffect(
      textBlockTwo,
      textBlockStartTween,
      textBlockEndTween,
      "top 55%"
    );
  }, []);

  const classes = useStyles();

  return (
    <div ref={contentWrapperRef} className={classes.contentWrapper}>
      <Container maxWidth="lg">
        <Grid
          className={classes.contentContainer}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.imgContainer} item xs={11} md={5}>
            <SVG ref={svgRef} className={classes.svg} />
          </Grid>
          <Grid className={classes.textContainer} item xs={11} md={7}>
            <Typography ref={headlineRef} className={classes.sectionHeader}>
              My Experiences with DevOps &amp; More
            </Typography>
            <Typography ref={textBlockOneRef} className={classes.sectionText}>
              This is a <span className={classes.underline}>responsive</span>{" "}
              and <span className={classes.underline}>secure</span> website that
              I have designed and built myself using modern technology. DevOps
              is very exciting and increasingly important in our rapidly
              changing world, and is linked directly to the success or failure
              of companies (depending on how it is utilized). We must take into
              account the associated business processes (requirements, user
              stories, agile, backlogs, QA, Testing, etc) that go along for the
              ride. Cloud-based DevOps is the fuel providing energy and motion
              to the process of modern software development and deployments and
              on-going maintenance.
            </Typography>
            <Typography ref={textBlockTwoRef} className={classes.sectionText}>
              There are so many awesome tools and technologies at our fingertips
              today (must be implemented wisely) that can be used to
              dramatically and drastically{" "}
              <span className={classes.underline}>cut costs</span> normally
              associated with building, managing and maintaining software over
              its lifetime. Software could be in production for months or even a
              decade or more. It is always important to talk about using DevOps
              whether it is to be used for new applications, older applications,
              and even legacy systems that require much attention to help{" "}
              <span className={classes.underline}>
                reduce the backlog of technical debt
              </span>
              .
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function makeScrollEffect(element, startTween, endTween, endPosition) {
  const endPos = endPosition ? endPosition : "top 60%";
  const tl = gsap.timeline();
  tl.set(element, startTween);
  tl.to(element, endTween);
  ScrollTrigger.create({
    animation: tl,
    trigger: element,
    start: "top 80%",
    end: endPos,
    scrub: 0.66,
  });
}

export default MyExperiencesWithDevOps;
