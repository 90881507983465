import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#82a6eb",
    width: "100%",
    borderTop: "10px solid #89b8ff",
    borderBottom: "10px solid #89b8ff",
    marginBottom: theme.spacing(2),
  },
  header: {
    textAlign: "center",
    color: theme.palette.colors.black,
    fontWeight: "bold",
    fontSize: "1.9em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3em",
    },
  },
}));

const SkillSectionHeader = React.forwardRef((props, ref) => {
  const skillSectionRef = ref;
  const headerText = props.text ? props.text : "header";
  const headerIndex = props.headerIndex;
  const headerRef = React.useRef();

  useEffect(() => {
    const section = skillSectionRef.current;
    const header = headerRef.current;

    const navbarHeight = 55;
    const startPosition = "top " + navbarHeight + "px";
    const headerGapScalar = 1;

    const tl = gsap.timeline();
    tl.set(header, { zIndex: 100 + headerIndex });
    tl.to(header, { opacity: 1, immediateRender: false });
    ScrollTrigger.create({
      animation: tl,
      trigger: header,
      start: startPosition,
      end: () => {
        return (
          section.offsetHeight - header.offsetHeight * headerGapScalar + "px"
        );
      },
      invalidateOnRefresh: true,
      pin: true,
      pinSpacing: false,
      scrub: true,
    });

    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  return (
    <div ref={headerRef} className={classes.root}>
      <Typography variant="h3" className={classes.header}>
        {headerText}
      </Typography>
    </div>
  );
});

export default SkillSectionHeader;
