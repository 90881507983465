import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Link } from "@material-ui/core";
import gsap from "gsap";
import { ScrollTrigger, SplitText } from "gsap/all";
import DevOps from "../../animations/DevOps";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflowX: "hidden",
    color: theme.palette.colors.black,
  },
  contentContainer: {
    margin: "50px 0",
  },
  sectionHeader: {
    fontSize: "34px",
    fontWeight: 700,
    textAlign: "center",
  },
  sectionText: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: "18px",
    fontWeight: 400,
  },
  imgContainer: {
    textAlign: "center",
    position: "relative",
    height: "500px",
    width: "500px",
    [theme.breakpoints.only("sm")]: {
      maxHeight: "400px",
      maxWidth: "400px",
    },
    [theme.breakpoints.only("xs")]: {
      maxHeight: "250px",
      maxWidth: "250px",
    },
  },
  svg: {
    position: "absolute",
    width: "100%",
    height: "auto",
  },
  textContainer: {
    textAlign: "left",
  },
  link: {
    color: theme.palette.colors.darkOrange,
  },
}));

function DevOpsInfinityLoop(props) {
  const contentWrapperRef = React.useRef();
  const headlineRef = React.useRef();
  const textBlockOneRef = React.useRef();
  const textBlockTwoRef = React.useRef();
  const textBlockThreeRef = React.useRef();
  const devOpsLogoContainerRef = React.useRef();

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    const headline = headlineRef.current;
    const textBlocks = [
      textBlockOneRef.current,
      textBlockTwoRef.current,
      textBlockThreeRef.current,
    ];
    const devOpsLogoContainer = devOpsLogoContainerRef.current;

    // hide elements and reveal container
    const mainTL = gsap.timeline();
    mainTL.set(contentWrapper, { autoAlpha: 1 });

    // animate
    // headline
    animateHeadline(headline);
    // text blocks
    const textBlockStartTween = { y: 30, autoAlpha: 0 };
    const textBlockEndTween = { y: 0, autoAlpha: 1 };
    textBlocks.forEach((textBlock) => {
      makeScrollEffect(
        textBlock,
        textBlockStartTween,
        textBlockEndTween,
        "top 55%"
      );
    });
    // svg
    const svgStartTween = { y: 40, autoAlpha: 0 };
    const svgEndTween = { y: 0, autoAlpha: 1 };
    makeScrollEffect(
      devOpsLogoContainer,
      svgStartTween,
      svgEndTween,
      "top 50%"
    );
  }, []);

  const classes = useStyles();

  return (
    <div ref={contentWrapperRef} className={classes.contentWrapper}>
      <Container maxWidth="lg">
        <Grid
          className={classes.contentContainer}
          container
          justifyContent="center"
        >
          <Grid className={classes.textContainer} item xs={11}>
            <Typography ref={headlineRef} className={classes.sectionHeader}>
              The DevOps Infinity Loop
            </Typography>
            <Typography ref={textBlockOneRef} className={classes.sectionText}>
              This graphic (see below) depicts the sequence of events that I
              follow when creating software applications using an Agile approach
              (starting with the PLAN). It is an infinite loop that is
              continously being followed and advances to the next step, as work
              is completed on the software application or project. When the
              defined and approved tasks are completed, the project is deployed
              into production and monitored, allowing the devops loop to begin
              again at the planning stage.
            </Typography>
            <Typography ref={textBlockTwoRef} className={classes.sectionText}>
              The basic idea of agility, is to continue making rapid iterations
              of approved changes and deploying them into production, based on
              the changing customer requirements. The end users and customers
              always have access to a 'live' version of the work-in-progress,
              that allows a constant feedback loop to be established, which
              quickens the pace of change. That is the basis of being 'agile' in
              my opinion.
            </Typography>
            <Typography ref={textBlockThreeRef} className={classes.sectionText}>
              Keep in mind that some of this devops work is done manually by
              programmers and other teams members, and other work is automated.
              I show a working example of a real project implementation in the
              process{" "}
              <Link href="#theFlowchart" className={classes.link}>
                flowchart below
              </Link>
              .
            </Typography>
          </Grid>
          <Grid
            ref={devOpsLogoContainerRef}
            className={classes.imgContainer}
            item
            xs={11}
          >
            <DevOps />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function animateHeadline(headline) {
  const tl = gsap.timeline();
  const text = new SplitText(headline, { type: "words" });

  tl.set(text.words, { autoAlpha: 0, y: 10 });
  tl.to(text.words, { autoAlpha: 1, y: 0, stagger: 0.2 });

  ScrollTrigger.create({
    animation: tl,
    trigger: headline,
    start: "top 80%",
    end: "top 60%",
    scrub: 0.66,
  });
}

function makeScrollEffect(element, startTween, endTween, endPosition) {
  const endPos = endPosition ? endPosition : "top 60%";
  const tl = gsap.timeline();
  tl.set(element, startTween);
  tl.to(element, endTween);
  ScrollTrigger.create({
    animation: tl,
    trigger: element,
    start: "top 80%",
    end: endPos,
    scrub: 0.66,
  });
}

export default DevOpsInfinityLoop;
