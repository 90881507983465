import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import MakeTheme from "./MakeTheme";
import Content from "./Content";

library.add(far, fas, fab);

function App() {
  const theme = MakeTheme();
  // A note about security:
  // This should never be the be-all-end-all of security but you should never provide a user an action/route that they can't actually access.
  // Consider hiding those links in the hamburger/nav menu (and possibly hide links in other areas of the app too - so do we need
  // a HideLink helper function - so we dont show any links to users that should not see them - like links contained in some of the
  // other Pages on this website) that are supposed to be protected, so that users never see them and cant click on them in the first place.
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Content />
    </ThemeProvider>
  );
}

export default App;
