import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
import gsap from "gsap";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerText: {
    fontSize: "16px",
    lineHeight: "24px",
    margin: "20px",
    visibility: "hidden",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
  headerDoubleLines: {
    fontWeight: 700,
    fontStyle: "italic",
  },
}));

function HomePageHeaderText(props) {
  const textBlockOneRef = React.useRef();
  const textBlockTwoRef = React.useRef();
  const textBlockThreeRef = React.useRef();

  useEffect(() => {
    const textBlockOne = textBlockOneRef.current;
    const textBlockTwo = textBlockTwoRef.current;
    const textBlockThree = textBlockThreeRef.current;

    const mainTL = gsap.timeline();

    mainTL.to(
      [textBlockOne, textBlockTwo, textBlockThree],
      {
        autoAlpha: 1,
        duration: 0.66,
        stagger: 0.1,
      },
      0.3
    );
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography ref={textBlockOneRef} className={classes.headerText}>
        This website <b>showcases</b> just some of the accomplishments and
        skills I have acquired along my journey through life and the world of{" "}
        <i>Information Technology</i> such as: software and hardware I have
        worked on <span className={classes.headerDoubleLines}>||</span>{" "}
        companies and projects I have created or managed{" "}
        <span className={classes.headerDoubleLines}>||</span> testimonials and
        reference letters I have received over the years{" "}
        <span className={classes.headerDoubleLines}>||</span> websites and
        applications I built{" "}
        <span className={classes.headerDoubleLines}>||</span> passion projects I
        designed and created{" "}
        <span className={classes.headerDoubleLines}>||</span> online versions of
        my resume <span className={classes.headerDoubleLines}>||</span> long
        list of skills I possess{" "}
        <span className={classes.headerDoubleLines}>||</span> technical youtube
        videos I have made <span className={classes.headerDoubleLines}>||</span>{" "}
        an online blog I created and maintain{" "}
        <span className={classes.headerDoubleLines}>||</span> a few applications
        I created that are available for sale to you and future customers.
      </Typography>
      <Typography ref={textBlockTwoRef} className={classes.headerText}>
        With all that said, I use all these experiences and knowledge to help
        your company and teams in many areas throughout the organization.{" "}
        <Link href="#" target="_blank" rel="noopener" color="secondary">
          Note: click here
        </Link>{" "}
        to learn more about my skills and software that are for sale.
      </Typography>
      <Typography ref={textBlockThreeRef} className={classes.headerText}>
        This website explains in words, and visually shows you in diagrams and
        flowcharts my experiences with DevOps and other related technology. I
        explain why it is so important to use various devops practices in
        projects whether you have existing software or are planning on
        undertaking new projects.
      </Typography>
    </div>
  );
}

export default HomePageHeaderText;
