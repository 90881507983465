import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import gsap from "gsap";

const useStyles = makeStyles((theme) => ({
  root: {},
  headerText: {
    fontSize: "16px",
    lineHeight: "24px",
    margin: "20px",
    visibility: "hidden",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
  },
}));

function SkillsPageHeaderText(props) {
  const textBlockOneRef = React.useRef();
  const textBlockTwoRef = React.useRef();

  useEffect(() => {
    const textBlockOne = textBlockOneRef.current;
    const textBlockTwo = textBlockTwoRef.current;

    const mainTL = gsap.timeline();

    mainTL.to(
      [textBlockOne, textBlockTwo],
      {
        autoAlpha: 1,
        duration: 0.66,
        stagger: 0.1,
      },
      0.3
    );
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography ref={textBlockOneRef} className={classes.headerText}>
        This web page <b>lists</b> many of the skills I have acquired over the
        years as I have worked in the world of <i>Information Technology</i>.
        The skills are grouped together into various categories. In some cases,
        you can move your mouse over the skills to see a popup with more
        information about that particular skill or piece of technology.
      </Typography>
      <Typography ref={textBlockTwoRef} className={classes.headerText}>
        The groups of information you see below include technology skills,
        business skills, communication skills, management skills, and personal
        soft skills.
      </Typography>
    </div>
  );
}

export default SkillsPageHeaderText;
