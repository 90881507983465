import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Button,
  Typography,
  Menu,
  Collapse,
  useMediaQuery,
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SiteMap from "./SiteMap";

const MenuItem = withStyles({
  root: {
    justifyContent: "flex-end",
  },
})(MuiMenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.navbar,
  },
  siteTitle: {},
  navbarDisplayFlex: {
    display: `flex`,
    alignItems: "center",
    justifyContent: `space-between`,
  },
  signInButton: {
    color: theme.palette.colors.lightOrange,
    borderColor: theme.palette.colors.lightOrange,
    "&:hover": {
      backgroundColor: theme.palette.colors.lightOrange,
      color: theme.palette.colors.black,
    },
  },
  iconButton: {
    height: "37px",
    width: "20px",
    border: "0.5px solid rgba(255, 255, 255, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.05)",
    },
    "& svg": {
      width: "55%",
      height: "auto",
    },
    "& path": {
      color: theme.palette.colors.lightGray,
    },
    borderRadius: "5px",
  },
  siteNavigationMenu: {
    zIndex: "900 !important",
    "& .MuiMenu-paper": {
      backgroundColor: theme.palette.background.siteNavigationMenu,
      marginTop: "4px",
    },
    "& li, li a": {
      fontWeight: "700",
      fontSize: "18px",
      color: theme.palette.colors.white,
      textDecoration: "none !important",
    },
    "& li:hover, li a:hover": {
      color: theme.palette.links.color,
      backgroundColor: "initial",
    },
  },
  siteNavigationLink: {},
  nestedListIcon: {
    paddingLeft: theme.spacing(1),
  },
  nestedListItem: {
    paddingRight: theme.spacing(4),
    fontSize: "1.2em !important",
  },
  placeholderNavBar: {
    visibility: "hidden",
  },
}));

function Navbar(props) {
  let navigationLinks = SiteMap();
  navigationLinks.pop();
  // I want to filter out the navigation links contained in the SiteMap.js file
  // that I dont want to display in the hamburger menu in the Navbar.
  // Those are pages that have a property of hideThisinNavBar = true
  // TODO: add null/undefined check for a missing hideThisinNavBar in one of the pages
  let navigationLinks2 = navigationLinks.filter(
    (thelink) => thelink.hideThisinNavBar !== true
  );

  const location = useLocation();

  let pageTitle = "ScottDevOps";
  navigationLinks2.forEach((page) => {
    if (page.path === location.pathname && page.title) {
      pageTitle = page.title;
    }
  });

  const [hamburgerAnchorEl, setHamburgerAnchorEl] = React.useState(null);
  const [nestedListTryBuyOpen, setNestedListTryBuyOpen] = React.useState(false);
  const [nestedListBuyOpen, setNestedListBuyOpen] = React.useState(false);

  const handleHamburgerClick = (event) => {
    if (hamburgerAnchorEl) {
      setHamburgerAnchorEl(null);
    } else {
      setHamburgerAnchorEl(event.currentTarget);
    }
  };
  const closeSiteNavigationMenu = () => {
    setHamburgerAnchorEl(null);
  };
  const handleNestedListTryBuyClick = () => {
    setNestedListTryBuyOpen(!nestedListTryBuyOpen);
  };
  const handleNestedListBuyClick = () => {
    setNestedListBuyOpen(!nestedListBuyOpen);
  };

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {}, []);

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Container maxWidth="lg" className={classes.navbarDisplayFlex}>
            <Button
              onClick={() => navigate("/login")}
              className={classes.signInButton}
              aria-label="sign in button"
              variant="outlined"
              // color={theme.palette.colors.lightOrange}
            >
              Sign In
            </Button>
            <Typography
              variant="h6"
              align="center"
              className={classes.siteTitle}
            >
              <Box component="span" display={{ xs: "none", md: "block" }}>
                {pageTitle}
              </Box>
            </Typography>
            <Button
              className={[classes.iconButton].join(" ")}
              ari-label="toggle site navigation menu"
              aria-controls="site-navigation-menu"
              aria-haspopup="true"
              onClick={handleHamburgerClick}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
      {/* this hack is one of the actual suggestions for a sticky header that won't overlay the page content */}
      <Toolbar className={classes.placeholderNavBar}></Toolbar>

      {/* the menu */}
      <Menu
        id="site-navigation-menu"
        anchorEl={hamburgerAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(hamburgerAnchorEl)}
        onClose={closeSiteNavigationMenu}
        disableScrollLock={!smallScreen}
        keepMounted
        TransitionComponent={Slide}
        className={classes.siteNavigationMenu}
      >
        {navigationLinks2.map((link) => {
          const internalLink = (
            <Link
              className={classes.siteNavigationLink}
              to={link.path}
              key={link.path}
            >
              {link.linkText}
            </Link>
          );
          const externalLink = (
            <a
              className={classes.siteNavigation}
              href={link.path}
              target="_blank"
              rel="noopener noreferrer"
              key={link.path}
            >
              {link.linkText}
            </a>
          );
          const newLink =
            link.externalLink === true ? externalLink : internalLink;
          return (
            <MenuItem
              onClick={closeSiteNavigationMenu}
              key={"key-" + link.linkText}
            >
              {newLink}
            </MenuItem>
          );
        })}
        {/* nested list Try/Buy */}
        <MenuItem button onClick={handleNestedListTryBuyClick}>
          My Software to Try/Buy
          <span className={classes.nestedListIcon}>
            {nestedListTryBuyOpen ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </span>
        </MenuItem>
        <Collapse in={nestedListTryBuyOpen} timeout="auto" unmountOnExit>
          <MenuItem
            className={classes.nestedListItem}
            onClick={closeSiteNavigationMenu}
          >
            <MuiLink
              href="https://lofigenerator.com/"
              target="_blank"
              rel="noopener"
            >
              To-Do App
            </MuiLink>
          </MenuItem>
          <MenuItem
            className={classes.nestedListItem}
            onClick={closeSiteNavigationMenu}
          >
            <MuiLink
              href="https://lofigenerator.com/"
              target="_blank"
              rel="noopener"
            >
              Project Tracker
            </MuiLink>
          </MenuItem>
          <MenuItem
            className={classes.nestedListItem}
            onClick={closeSiteNavigationMenu}
          >
            <MuiLink
              href="https://lofigenerator.com/"
              target="_blank"
              rel="noopener"
            >
              Reminders
            </MuiLink>
          </MenuItem>
        </Collapse>
        {/* nested list Buy */}
        <MenuItem button onClick={handleNestedListBuyClick}>
          My Software Apps to Buy
          <span className={classes.nestedListIcon}>
            {nestedListBuyOpen ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </span>
        </MenuItem>
        <Collapse in={nestedListBuyOpen} timeout="auto" unmountOnExit>
          <MenuItem
            className={classes.nestedListItem}
            onClick={closeSiteNavigationMenu}
          >
            <MuiLink
              href="https://lofigenerator.com/"
              target="_blank"
              rel="noopener"
            >
              F.U.E.L. Knowledge App
            </MuiLink>
          </MenuItem>
          <MenuItem
            className={classes.nestedListItem}
            onClick={closeSiteNavigationMenu}
          >
            {" "}
            <MuiLink
              href="https://lofigenerator.com/"
              target="_blank"
              rel="noopener"
            >
              Membership Manager App
            </MuiLink>
          </MenuItem>
        </Collapse>
      </Menu>
    </div>
  );
}

export default Navbar;
