import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { ReactComponent as SVG } from "../../img/ci-cd-flowchart.svg";
import gsap from "gsap";
import { ScrollTrigger, SplitText } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    overflowX: "hidden",
    color: theme.palette.colors.black,
  },
  contentContainer: {
    margin: "50px 0",
  },
  sectionHeader: {
    fontSize: "34px",
    fontWeight: 700,
    textAlign: "center",
  },
  sectionText: {
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: "18px",
    fontWeight: 400,
  },

  imgContainer: {
    textAlign: "center",
    position: "relative",
    minHeight: "500px",
    width: "500px",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.only("sm")]: {
      minHeight: "400px",
      width: "400px",
    },
    [theme.breakpoints.only("xs")]: {
      minHeight: "250px",
      width: "250px",
    },

    "& svg": {
      width: "96%",
      maxWidth: "900px",
      height: "auto",
    },
  },

  textContainer: {
    textAlign: "left",
  },
  link: {
    color: theme.palette.colors.darkOrange,
  },
}));

function CICDProcessFlowchart(props) {
  const contentWrapperRef = React.useRef();
  const headlineRef = React.useRef();
  const textBlockOneRef = React.useRef();
  const flowchartSVGContainerRef = React.useRef();

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    const headline = headlineRef.current;
    const textBlockOne = textBlockOneRef.current;
    const flowchartSVGContainer = flowchartSVGContainerRef.current;

    // hide elements and reveal container
    const mainTL = gsap.timeline();
    mainTL.set(contentWrapper, { autoAlpha: 1 });

    // animate
    // headline
    animateHeadline(headline);
    // text blocks
    const textBlockStartTween = { y: 30, autoAlpha: 0 };
    const textBlockEndTween = { y: 0, autoAlpha: 1 };
    makeScrollEffect(
      textBlockOne,
      textBlockStartTween,
      textBlockEndTween,
      "top 55%"
    );
    // svg
    const svgStartTween = { y: 40, autoAlpha: 0 };
    const svgEndTween = { y: 0, autoAlpha: 1 };
    makeScrollEffect(
      flowchartSVGContainer,
      svgStartTween,
      svgEndTween,
      "top 50%"
    );
  }, []);

  const classes = useStyles();

  return (
    <div ref={contentWrapperRef} className={classes.contentWrapper}>
      <Container maxWidth="lg">
        <Grid
          className={classes.contentContainer}
          container
          justifyContent="center"
        >
          <Grid className={classes.textContainer} item xs={11}>
            <Typography ref={headlineRef} className={classes.sectionHeader}>
              My CI/CD Process Flowchart for this Web Application
            </Typography>
            <Typography ref={textBlockOneRef} className={classes.sectionText}>
              These are the overall steps that are used to deploy this web
              application that you are currently looking at, to a server located
              somewhere on the internet or published to an in-house server
              behind an enterprise corporate firewall.{" "}
              <i>
                Please note there are many hyperlinks you can click on in the
                flowchart shown below that will open up useful websites to
                provide additional information on those topics.
              </i>
            </Typography>
          </Grid>
          <Grid
            ref={flowchartSVGContainerRef}
            className={classes.imgContainer}
            item
            xs={11}
          >
            <SVG />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function animateHeadline(headline) {
  const tl = gsap.timeline();
  const text = new SplitText(headline, { type: "words" });

  tl.set(text.words, { autoAlpha: 0, y: 10 });
  tl.to(text.words, { autoAlpha: 1, y: 0, stagger: 0.2 });

  ScrollTrigger.create({
    animation: tl,
    trigger: headline,
    start: "top 80%",
    end: "top 60%",
    scrub: 0.66,
  });
}

function makeScrollEffect(element, startTween, endTween, endPosition) {
  const endPos = endPosition ? endPosition : "top 60%";
  const tl = gsap.timeline();
  tl.set(element, startTween);
  tl.to(element, endTween);
  ScrollTrigger.create({
    animation: tl,
    trigger: element,
    start: "top 80%",
    end: endPos,
    scrub: 0.66,
  });
}

export default CICDProcessFlowchart;
