import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Link, TextField, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.background.header,
    overflow: "hidden",
    position: "relative",
    minHeight: "calc(100vh - 95px)",
  },
  headerH1: {
    color: theme.palette.text.headerH1Color,
    fontSize: theme.typography.headerH1.fontSize,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontSize: "1.3rem",
    padding: "2em 0",
  },
  headerImage: {
    textAlign: "center",
  },
}));

function LoginHeader(props) {
  useEffect(() => {}, []);

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    var str = "";

    for (var p in e) {
      if (typeof e[p] == "string") {
        str += p + ": " + e[p] + "; </br>";
      } else {
        str += p + ": { </br>" + console.log(e[p]) + "}";
      }
    }
    console.table(`values : ${str}`);
    //console.table([...e]); // could use console.table if you like that
  };

  return (
    <Grid
      className={classes.headerContainer}
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid container item xs={11}>
        <Grid className={classes.hero} item xs={12} md={7}>
          <Typography variant="h1" className={classes.headerH1} align="center">
            Login to the System
          </Typography>
          <Typography className={classes.subHeader}>
            This is the place to login to our system. You must have an approved
            and authenticated account before you can login. If you do not have
            an account, you can click here to go to the{" "}
            <Link
              href="/register"
              target="_blank"
              rel="noopener"
              color="secondary"
            >
              Account Registration Page.
            </Link>
            <br />
            <form onSubmit={handleSubmit}>
              <TextField id="youremail" label="Your email" margin="normal" />{" "}
              <TextField
                id="password"
                label="password"
                margin="normal"
                type="password"
              />
              <pre>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  style={{ marginTop: "25px", marginLeft: "5px" }}
                >
                  <Typography
                    style={{
                      textAlign: "left",
                      minWidth: "175px",
                      textTransform: "none",
                    }}
                  >
                    Login Now
                  </Typography>
                </Button>
              </pre>
            </form>
          </Typography>
        </Grid>
        <Grid className={classes.headerRight} item xs={12} md={5}>
          <div className={classes.headerImage}>
            <img
              src="https://i.picsum.photos/id/2/400/300.jpg?grayscale&hmac=oV_ybjwxp4S7HHkw6y0u-oWRpcIlG9NWsJzZ1eGMCL4"
              alt="login"
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoginHeader;
